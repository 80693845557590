import Button from '@components/views/Button';
import omit from 'lodash/omit';
import React from 'react';
import slugify from 'slugify';
import { IButtonACF } from 'src/types/IButtonACF';

const ButtonACF = (
  props: IButtonACF & { noPadding?: boolean; fullHeight?: boolean },
) => {
  const { id } = props;

  //button id needs to be valid with html id attribute syntax
  const validId =
    id &&
    slugify(id, {
      replacement: '_',
      strict: true,
    });

  return (
    <Button
      {...omit(props, 'id')}
      {...(validId && { id: validId })}
      href={props.url}
    >
      <div dangerouslySetInnerHTML={{ __html: props.title }} />
    </Button>
  );
};

export default ButtonACF;
